<template>
  <v-container>
    <v-card class="pa-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-subheader>Data</v-subheader>
          <v-row no-gutters v-for="item in formText" :key="item.id">
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                item.title
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field outlined dense v-model="item.value"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold"
                >Phone Number</v-subheader
              >
            </v-col>
            <v-col cols="12" md="7">
              <v-row>
                <v-col cols="4">
                  <v-select outlined dense label="Code" :items="filteredList" :item-text="item => `${item.code} - ${item.name}`" item-value="code"></v-select>
                </v-col>
                <v-col cols="8">
                  <v-text-field outlined dense label="Number" v-mask="['#######']"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-subheader>Map</v-subheader>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import countries from '../utils/countries'
import { mask } from "vue-the-mask";


export default {

  directives: { mask },


  data() {
    return {

      countryList: countries,
      filteredList:  [],
      formText: [
        {
          id: "firstName",
          title: "First Name",
          value: "",
        },
        {
          id: "lastName",
          title: "Last Name",
          value: "",
        },
        {
          id: "email",
          title: "Email",
          value: "",
        },
        {
          id: "password",
          title: "Password",
          value: "",
        },
        {
          id: "licenseNumber",
          title: "License Number",
          value: "",
        },
      ],
    };
  },

  created () {
    // console.log(...[this.countryList]);

    this.countryList.forEach(item => {
      // console.log(Object.keys(item))
      this.filteredList.push({
        mini: Object.keys(item)[0],
        name: item[Object.keys(item)[0]].name,
        code: item[Object.keys(item)[0]].code
      })
    })

    console.log(this.filteredList)
  },
};
</script>

<style>
</style>